<template>
    <div>
        <VueApexCharts
            width="100%"
            height="200"
            :options="chartOptions"
            :series="series"
        ></VueApexCharts>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    name: 'HorizontalBar',
    props: ['question'],
    components: {VueApexCharts},
    data() {
        return {
            report: {
                keys: [],
                values: []
            },
        };
    },
    mounted() {
        this.extractKeysAndValues()
    },
    methods: {
        extractKeysAndValues() {
            this.report.keys = (this.question.report.map(a => a.key))
            this.report.values = (this.question.report.map(a => a.percentage))
        }
    },
    computed: {
        series() {
          if (this.report.values === 0) {
            return this.hideSeries()
          }else
            return [
                {
                  name: 'Percentage',
                  data: this.report.values
                }
            ]
        },
        chartOptions() {
            return {
                chart: {
                    type: "bar",
                    height: 'auto',
                    fontFamily: 'Inter, sans-serif',
                    redrawOnParentResize: true,
                    stacked: true,
                    animations: {
                      enabled: false
                    },
                    toolbar: {
                      show: false
                    }
                },
                noData: {
                  text: 'There is no data for this question'
                },
                theme: {
                  palette: 'palette10',
                  monochrome: {
                      enabled: false,
                      color: '#0B2559',
                      shadeTo: 'light',
                      shadeIntensity: 0.65
                  },                
                },
                plotOptions: {
                  bar: {
                    borderRadius: 5,
                    barHeight: '75%',
                    horizontal: true,
                    columnWidth: '45%',
                    distributed: true,
                    labels: {
                      show: true,
                      value: {
                        show: true,
                        fontSize: "30px",
                        fontWeight: 600,
                        formatter: function (val) {
                          return val + '%';
                        },
                      },
                    },
                  }
                },
                tooltip: {
                  enabled: true,
                  enabledOnSeries: undefined,
                  shared: true,
                  followCursor: false,
                  intersect: false,
                  inverseOrder: false,
                  custom: undefined,
                  fillSeriesColor: false,
                  theme: false,
                  style: {
                    fontSize: '12px',
                  },
                  marker: {
                    show: false
                  },
                  onDatasetHover: {
                    highlightDataSeries: false,
                  },                 
                  y: {
                    formatter: function(value) {
                      return value + '%'
                    },
                    title: {
                      show: false,
                      formatter: (seriesName) => seriesName,
                    },
                  }
                },
                legend: {
                  show: false,
                  floating: true
                },
                dataLabels: {
                  enabled: true,
                  formatter: function(val) {
                    if(val !== 0) {
                      return val + '%'
                    }
                  },                  
                },
                grid: {
                  show: false
                },
                xaxis: {
                  type: 'category',
                  categories: this.report.keys,
                  labels: {
                    show: false
                  },
                  axisTicks: {
                    show: false
                  }
                },
                yaxis: {
                  type: 'numeric',
                  min: 0,
                  max: 100,
                  //showForNullSeries: false,                 
                  labels: {
                    style: {
                      fontWeight: '600'
                    }                   
                  }
                },
                responsive: [
                  {
                    breakpoint: 767.98,
                    options: {
                      chart: {
                        height: '400px',
                        width: '100%'
                      },
                      plotOptions: {
                        bar: {
                          barHeight: '90%',
                          horizontal: false,
                          columnWidth: '90%',
                          dataLabels: {
                            position: 'center',
                            //hideOverflowingLabels: true,
                            orientation: 'vertical'
                          }
                        }
                      },
                      xaxis: {
                        labels: {
                          show: true,
                          //maxHeight: 50,
                          trim: true,
                        },
                        axisTicks: {
                          show: true
                        }
                      },
                      yaxis: {
                        showForNullSeries: false,
                        labels: {
                          trim: true,
                          show: false,
                          //rotate: 45
                        }
                      }
                    }
                  }
                ]
            }
        }
    }
};
</script>
