<template>
    <div>
        <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">

            <div class="page-title">
                <h4 class="font-weight-semibold">{{ survey.title }}</h4>
                <div class="survey-status">
                    <span :class="`status status-${survey.status}`">
                        {{ survey.status }}
                    </span>
                </div>
            </div>

          <div v-if="!wait && !survey.published"
               class="alert alert-info">
            <h6 class="mb-1"><strong>This survey is a draft and has not been published.</strong></h6>
            <p class="mb-3">Your survey has not collected any responses yet. Publish if you would like to
              collect responses for reports.</p>
          </div>


          <Alerts v-if="!survey.published" :_success="success" :_error="error"></Alerts>
            <!-- Summary cards -->
            <div class="row mb-4 g-2 g-md-4">

                <!-- Questions -->
                <div class="col-lg-3 col-md-6 mb-3 mb-md-3 col-6 mb-lg-0">
                    <div class="info-card">
                        <h6>Questions</h6>
                        <p class="large-number">{{ survey.questions_count }}</p>
                    </div>
                </div>

                <!-- Total views -->
                <div class="col-lg-3 col-md-6 mb-3 mb-md-3 col-6 mb-lg-0">
                    <div class="info-card">
                        <h6>Total Views</h6>
                        <p class="large-number">{{ survey.views }}</p>
                    </div>
                </div>

                <!-- Responses -->
                <div class="col-lg-3 col-md-6 mb-3 mb-md-3 mb-lg-0 col-6">
                    <div class="info-card">
                        <h6>Total Responses</h6>
                        <p class="large-number">{{ survey.respondents_count }}</p>
                    </div>
                </div>

                <!-- Avg. response rate -->
                <div class="col-lg-3 col-md-6 mb-3 mb-md-3 mb-lg-0 col-6">
                    <div class="info-card">
                        <h6>Avg. Response Rate</h6>
                        <p class="large-number">
                            {{ survey.responseRate }}%
                            <!--            <span v-if="dashboard.respondent_trend == 'up'" class="trend">-->
                            <!--                            <font-awesome-icon icon="arrow-up" />-->
                            <!--                        </span>-->
                        </p>
                    </div>
                </div>

            </div><!-- End row -->

            <!-- Responses -->
            <div v-if="survey.respondents_count !== 0">
                <div v-for="(qn, index) in questions" :key="index" >

                    <div v-if="qn.report.length">

                        <!-- Question title -->
                        <p class="question">{{ qn.title }}</p>

                        <div class="question-stat">
                            <div class="question-stat-actions">
                                <ul>
                                    <li><a @click.prevent="clickBar()" @change="changeView($event)" href="#" :question="qn"
                                           :class="barChart ? 'active' : ''" title="Bar Chart">
                                        <font-awesome-icon icon="chart-bar"/>
                                    </a></li>
                                    <li><a @click.prevent="clickPie()" @change="changeView($event)"  href="#" :question="qn"
                                           :class="pieChart ? 'active' : ''"
                                           title="Pie chart">
                                        <font-awesome-icon icon="chart-pie"/>
                                    </a></li>
                                    <li><a @click.prevent="clickTable()" @change="changeView($event)"  href="#" :question="qn"
                                           :class="tableView ? 'active' : ''"
                                           title="Table">
                                        <font-awesome-icon icon="table"/>
                                    </a></li>
                                    <li class="me-1 float-right" style="list-style-type:none">
                                        <button v-if="tableView"
                                                class="survey-status custom-btn custom-btn-outline custom-btn-success custom-btn-xs"
                                                @click="exportCsv()"
                                                title="Download Table in a CSV document">
                                            <font-awesome-icon icon="arrow-down"/>
                                            CSV
                                        </button>
                                    </li>
                                </ul>
                              <div>
                                <HorizontalBar  v-if="barChart" :question="qn"/>
                                <PieChart v-if="pieChart" :question="qn"/>
                                <TableView v-if="tableView" :question="qn" :survey_code="code"/>
                              </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div v-else class="posts-empty">
                <div class="posts-empty-inner">
                    <div class="posts-empty-inner_img">
                        <img loading="lazy" src="@/assets/img/graph.png" width="80" height="80"
                             alt="Empty survey"/>
                    </div>
                    <h4>This survey has no responses.</h4>
                    <h6 class="mb-4">Start collecting responses to generate your survey report.</h6>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Alerts from "@/components/Views/Helpers/Alerts";
import HorizontalBar from "@/components/Views/Charts/HorizontalBar";
import PieChart from "@/components/Views/Charts/PieChart";
import TableView from "@/components/Views/Charts/Table";

export default {
    name: "SurveyReports",
    components: {PieChart, HorizontalBar, TableView, Alerts},
    props: ['code', 'question'],
    data() {
        return {
            el: '',
            survey: {},
            questions: [],
            barChart: true,
            pieChart: false,
            tableView: false
        }
    },
    mounted() {
        this.loadSurvey();
        this.loadReportQuestions()
    },
    // computed: {
    //     set: function(questions) {
    //       questions.forEach(function (el, idx) {
    //         this.questions[idx] = el;
    //       })
    //       return questions
    //     }
    // },
    methods: {
        loadSurvey() {
            this.$http.get("/api/reports/survey/" + this.code).then(response => {
                this.survey = response.data.data
            }).catch(error => {
                this.showError(error)
            });
        },
        loadReportQuestions() {
            this.clear()
            this.success = 'Please wait..'
            this.$http.get(`/api/reports/${this.code}`).then(response => {
                this.questions = response.data.data
                this.success = ''
            }).catch(error => {
                this.showError(error)
            })
        },
        exportCsv() {
            this.clear()
            this.success = "Exporting responses please wait..."
            // let config = {
            //   responseType: 'blob',
            //   params: {
            //     selected: this.selected
            //   },
            // }

            this.$http.get(`/api/export/csv/${this.code}`).then(response => {
                const blob = new Blob([response.data], {type: 'text/csv'})
                const link = document.createElement('a')

                link.href = URL.createObjectURL(blob)
                link.download = this.survey.title + ' ' + ' Respondents'
                link.click()
                URL.revokeObjectURL(link.href)
                this.success = "Done."
            }).catch(error => {
                this.showError(error)
            })
        },
        clickBar() {
            this.barChart = true
            this.pieChart = false
            this.tableView = false
        },
        clickPie() {
            this.pieChart = true
            this.barChart = false
            this.tableView = false
        },
        clickTable() {
            this.pieChart = false
            this.barChart = false
            this.tableView = true
        }
    }
}
</script>

<style scoped>

</style>