<template>
    <div>
        <h5>Reports Table - Survey {{ survey_code }}</h5>
        <table class="table table-bordered">
            <thead>
            <tr>
                <th>Metric</th>
                <th class="text-right">Value</th>
                <th style="width: 50%"></th>
                <th class="text-right">Percentage</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(i, index) in question.report" :key="index">
                <td>{{ i.key }}</td>
                <td class="text-right">{{ i.count }}</td>
                <td>
                    <div class="progress">
                        <div class="progress-bar bg-info progress-bar-striped" :style="'width:'+i.percentage+'%'">
                            {{ i.percentage }}%
                        </div>
                    </div>
                </td>
                <td class="text-right">{{ i.percentage }}%</td>
            </tr>
            <tr class="text-danger">
                <td class="text-right">Did not Respond</td>
                <td class="text-right">{{ question.no_responsens_count }}</td>
                <td>
                    <div class="progress">
                        <div class="progress-bar bg-danger progress-bar-striped" :style="'width:'+question.no_responsens_count_pec+'%'">
                            {{ question.no_responsens_count_pec }}%
                        </div>
                    </div>
                </td>
                <td class="text-right">{{ question.no_responsens_count_pec }}%</td>
            </tr>
            <!--            <tr>-->
            <!--                <td class="text-right font-weight-semibold">Total</td>-->
            <!--            </tr>-->
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "Table",
    props: ['question', 'survey_code'],

    data() {
        return {
            report: {
                keys: [],
                values: []
            },
        }
    }
}
</script>

<style scoped>

</style>